import { useState } from 'react';
import { FormControl, Typography, Box, Paper } from '@material-ui/core';
import { patch } from '../../api'
import { Edit } from '@material-ui/icons';
import Button from '../Button'
import PhoneInput from '../PhoneInput'

const PhoneForm = (props) => {
  const [isEditting, setIsEditting] = useState(false)
  const [phone, setPhone] = useState(props.phone || '')
  const [saving, setSaving] = useState(false)
  
  function phoneWasSaved(resp) {
    setIsEditting(false)
  }

  function handleChange(event) {
    setPhone(event.target.value)
  }

  function handleSubmit(e) {
    if ( e ) e.preventDefault() // Submitted via enter key
    if ( saving ) return;
    setSaving(true)
    patch(`vendors/${props.vendorId}`, {vendor: {cell_phone: phone}})
    .then((resp) => {
      phoneWasSaved()
    })
    .catch(console.error)
    .finally(() => { setSaving(false) })
  }

  return (
    <Paper elevation={0}>
      <Typography variant="caption" style={{textTransform: 'uppercase'}}> Text Phone </Typography>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
          <Box flexGrow="1">
            {isEditting 
              ? <FormControl fullWidth>
                  <PhoneInput
                    label={false}
                    type="phone"
                    value={phone} 
                    fullWidth
                    onChange={ handleChange }
                  />
                </FormControl>
              : <Typography>{phone || 'No phone'}</Typography>
            }

          </Box>
          <Box>
            {isEditting
              ? <FormControl>
                  <Button
                    onClick={handleSubmit}
                    variant={isEditting ? 'contained' : false}
                    disableElevation
                    loading={saving}
                  >
                    {isEditting ? 'Save' : 'Edit'}
                  </Button>
                </FormControl> 
              : <Button onClick={ () => {setIsEditting(true)}}>
                  <Edit />
                </Button>
            }
          </Box>
        </Box>     
      </form>
    </Paper>
  )
}

export default PhoneForm
