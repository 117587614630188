import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import { post } from '../../api'
import Button from '../Button'
import Stack from '../Stack'

export default function AppCodeLogin() {
  const history = useHistory()
  const [appCode, setAppCode] = useState('')
  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)
  
  function handleLogin(e) {
    if ( e ) e.preventDefault()
    
    setSaving(true)
    
    if ( appCode === '' ) {
      setError('Code is required')
      return false;
    }

    post('login/code', {session: {code: appCode}})
      .then(resp => {
        if ( resp.ok ) return {}
        else return resp.json()
      })
      .then(json => {
        if ( json.error ) setError(json.error)
        else history.push('/')
      })
      .catch((err) => {
        debugger
      })
      .finally(() => { setSaving(false)})
  }
  
  function handleChange(event) {
    setAppCode(event.target.value)
  }

  return (
    <form onSubmit={handleLogin}>
      <Stack gutter={3}>
        <TextField
          label="Auth Code"
          autoComplete={'off'}
          fullWidth={true}
          name="appCode"
          value={appCode}
          error={error === null ? false : true}
          helperText={ error ? error : null}
          onChange={handleChange}
          onFocus={ () => {setError(null)} }
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            endIcon={<ArrowForwardIcon />}
            onClick={handleLogin}
            loading={saving}
          >
            Login
          </Button>
        </Box>
      </Stack>
    </form>
  )
}