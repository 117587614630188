import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import Modal from './components/Dialog'
import Alert from './components/Alert'
import NotFound from './components/pages/404'
import routes from './routes'
import SettingsContext from './settings'
import './assets/reset.css'

const pathNames = routes.map(r => r.path).flat()

function App() {
  const [show, setShow] = useState({})

  const settings = {
    show: show,
    setShow: (data) => {
      setShow(data)
      localStorage.setItem('wedding-expo-staff-settings', JSON.stringify(data))
    }
  }

  useEffect(() => {
    let stored = localStorage.getItem('wedding-expo-staff-settings')
    if ( stored !== null) {
      setShow(JSON.parse(stored))
    }
  }, [])

  return (
    <SettingsContext.Provider value={settings}>
      <Router>
        <Modal />
        <Alert />

          {routes.map(route => (
            <Route key={route.path} exact={route.exact} path={route.path}>
              {(routeProps) => (
                <CSSTransition
                  in={routeProps.match !== null}
                  classNames={`page`} // `page` must be final class name in list
                  timeout={300}
                  unmountOnExit
                >
                  <div className="page">
                    <route.component />
                  </div>
                </CSSTransition>
              )}
            </Route>
          ))}

          <Route exact path="/booths">
            <Redirect to="/booths/any" />
          </Route>

          <Route path="*">
            <NotFound pathNames={pathNames} />
          </Route>

      </Router>
    </SettingsContext.Provider>
  );
}

export default App;
