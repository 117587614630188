import { useState } from 'react';
import { Dialog, DialogTitle, Typography } from "@material-ui/core"


export default function ExpoDialog(props) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(!open)
  };
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>
      <Typography>Hi</Typography>
    </Dialog>
  )
}


