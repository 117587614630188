import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import SideNav from './SideNav'

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
  },
  menuButton: {
    color: '#333'
  },
  title: {
    flexGrow: 1,
  },
}));

export default function MenuAppBar(props) {
  const history = useHistory()
  const classes = useStyles();
  const [navOpen, setNavOpen] = React.useState(false)
  
  const toggleNav = () => {
    setNavOpen(!navOpen)
  }
  
  const goBack = () => {
    history.goBack()
  }

  return (
    <div className={classes.root}>
      <SideNav open={navOpen} toggleNav={toggleNav} />
      <AppBar position="fixed" color="inherit" elevation={1}>
        <Toolbar variant="dense">
          <IconButton
            onClick={props.menu ? toggleNav : goBack}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            { props.menu ? <MenuIcon /> : <ArrowBackIos /> }
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </div>
  );
}
