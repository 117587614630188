import { useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert';

export default function ExpoAlert(props) {
  const [open, setOpen] = useState(false)
  let vertical = 'top', horizontal = 'center'
  
  return (
    <>
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={() => { setOpen(false) }}
      >
      <Alert
        elevation={2}
        variant="filled"
        severity={props.severity || 'info'}
        onClose={() => { setOpen(false) }}
      >
        {props.message || 'This is an alert'}
      </Alert>
    </Snackbar>    
    </>
  )
}