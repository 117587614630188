import Home from './components/pages/Home'
import Login from './components/pages/Login'
import Booth from './components/pages/Booth'
import Booths from './components/pages/Booths'
import Cakepull from './components/pages/CakePull';
import Highlighted from './components/pages/Highlighted';
import Map from './components/pages/Map'
import Surveys from './components/pages/Surveys';
import { Assignment, Cake, CardGiftcard, LocationOn, Star, SupervisorAccount } from '@material-ui/icons';

/*
  
  Routes contains all route data & components needed by the Router
  Route entries with a `nav` property will go in the nav in SideNav

*/


const Routes = [
  {
    text: 'Select A Show',
    path: '/',
    exact: true,
    component: Home,
    nav: {
      to: '/',
      icon: Star,
      divider: true
    }
  },
  {
    text: 'Map',
    path: '/map',
    component: Map,
    nav: {
      to: '/map',
      icon: LocationOn,
    },
  },
  {
    text: 'Vendors',
    path: '/booths/:section',
    component: Booths,
    nav: {
      to: '/booths/any',
      icon: SupervisorAccount,
    },
  },
  {
    text: 'Cake',
    path: '/cake',
    component: Cakepull,
    nav: {
      to: '/cake',
      icon: Cake,
    },
  },
  {
    text: 'Highlighted',
    path: '/highlighted',
    component: Highlighted,
    nav: {
      to: '/highlighted',
      icon: CardGiftcard,
    },
  },
  {
    text: 'Surveys',
    path: '/surveys',
    component: Surveys,
    nav: {
      to: '/surveys',
      icon: Assignment,
    },
  },
  {
    path: '/booth/:id',
    component: Booth
  }, 
  {
    path: ['/login/:mode', '/login'],
    component: Login
  },
]

export default Routes