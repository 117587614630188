import {useContext, useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router';
import CheckIcon from '@material-ui/icons/Check';
import { Box, Button, ButtonGroup, makeStyles } from '@material-ui/core';
import Header from '../Header'
import List from '../List';
import SectionsSelect from './SectionsSelect'
import SettingsContext from '../../settings';
import LoadingScreen from '../LoadingScreen';
import { get } from '../../api'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    flexGrow: 1
  },
  span: {
    paddingLeft: '8px',
    opacity: 0.4
  },
  select: {
    padding: 0
  }
}))

const Booths = () => {
  const { section } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const settings = useContext(SettingsContext)
  
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({checked_in: 'any'})
  const [booths, setBooths] = useState([])

  const boothsInSection = booths.filter(b => section === 'any' ? true : `${b.door}` === section)

  const filterButtons = [
    {
      text: 'Any',
      filter: 'any'
    },
    {
      text: 'Checked In',
      filter: true
    },
    {
      text: 'Not Checked In',
      filter: false
    }
  ]

  useEffect(() => {
    if ( settings.show.id === undefined ) return; 
    setLoading(true)
    let url = `shows/${settings.show.id}/booths?format=json`
    get(url)
      .then(resp => resp.json() )
      .then(json => {
        let mapped = json.map(booth => {
          let uiProps = {
            text: `${booth.text} - ${booth.door}`,
            url: `/booth/${booth.id}`, 
            icon: booth.checked_in ? <CheckIcon /> : null
          } 
          return {...booth, ...uiProps}
        })
        setBooths(mapped)
        setLoading(false)
      })
    
  }, [settings.show.id])

  function sectionChanged(event) {
    history.replace(`/booths/${event.target.value}`)
  }

  function updateFilters(filter) {
    setFilters((current) => ({...current, ...filter}) )
  }

  return (
    <>
      <Header title='Vendors' />
      <Box m={2}>
        <SectionsSelect value={section} onChange={sectionChanged} />       
      </Box>
      <Box m={2}>
        <ButtonGroup size="small" className={classes.root}>
          {filterButtons.map(setting => (
            <Button
              key={setting.filter}
              className={classes.button}
              onClick={() => { updateFilters({checked_in: setting.filter}) }}
              variant={setting.filter === filters.checked_in ? 'contained' : null}
              disableElevation
              >
                <span>{setting.text}</span>
                <span className={classes.span}>
                  {boothsInSection.filter(booth => (booth.checked_in === setting.filter || setting.filter === 'any' )).length}
                </span>
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      {loading 
        ? <LoadingScreen open={loading} />
        : <List listItems={boothsInSection.filter(booth => (booth.checked_in === filters.checked_in || filters.checked_in === 'any') )} />
      }
    </>
  )
}

export default Booths