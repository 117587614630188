import { Button as MUIButton, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '20px !important',
    width: '20px !important',
    margin: 'auto'
  },
  container: {
    background: 'rgba(255, 255, 255, 0.6)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
  },
  button: {
    position: 'relative',
  },
}))

export default function Button(props) {
  const classes = useStyles()
  
  function handleClick(e) {
    e.preventDefault()
    if ( props.loading ) return;
    props.onClick()
  }
  
  return (
    <MUIButton
      classes={{root: classes.button}}
      color={props.color}
      variant={props.variant}
      size={props.size || 'small'}
      disableElevation={props.disableElevation || false}
      endIcon={props.endIcon}
      disabled={props.loading}
      onClick={handleClick}
    >
      {props.loading && 
        <div className={classes.container}>
          <CircularProgress classes={{root: classes.root}} />
        </div>
      }
      {props.children}
    </MUIButton>
  )
}