import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function InputComponent(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

InputComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function PhoneInput(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl>
        <Input
          value={props.value}
          onChange={props.onChange}
          name="phone"
          inputComponent={InputComponent}
        />
      </FormControl>
    </div>
  );
}
