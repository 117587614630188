import { Box, Typography } from "@material-ui/core"
import { useLocation, useRouteMatch } from "react-router"
import Header from '../Header'

export default function NoRoute(props) {
  const location = useLocation()
  const match = useRouteMatch(props.pathNames.filter(path => path !== '/'))
  return (
    <>
      {match === null && location.pathname !== '/' && (
        <>
          <Header title="Not found" />
          <Box m={2}>
            <Typography>No page found for {location.pathname}</Typography>
          </Box>
        </>
      )}
    </>
  )
}