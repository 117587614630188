import { Box } from "@material-ui/core";

export default function Stack(props) {

  return (
    <Box className={props.className}>
      { props.children.map((child, i) => {
          return (
            <Box
              key={i}
              px={props.gutter || 0}
              py={props.space || 2}
            >
              {child}
            </Box>
          )
        })
      }
    </Box>
  )
}