import React from 'react';
import { get } from '../../api'
import SettingsContext from '../../settings';
import { FormControl, Select } from '@material-ui/core';

const SectionsSelect = (props) => {
  const settings = React.useContext(SettingsContext)
  const [loading, setLoading] = React.useState(false)
  const [sections, setSections] = React.useState([])

  React.useEffect(() => {
    if ( sections.length > 0 || settings.show.id === undefined) return; 
    // console.log('loading sections')
    setLoading(true)
    get(`shows/${settings.show.id}/sections`)
      .then(resp => resp.json())
      .then(json => {
        setSections(
          json.map(data => { 
            return {...data, ...{key: data.section, url: `booths/${data.section}`}} 
          })
        )
        setLoading(false)
      })
  }, [sections.length, settings.show.id])

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <Select
          native
          value={props.value}
          onChange={props.onChange}
          inputProps={{
            name: 'section',
          }}
        >
          <option value={'any'}>All Sections</option>
          {sections.map(data => (
            <option key={data.key} value={data.section}>
              {data.text}
            </option>
          ))}
          {loading && 
            <option>Loading...</option>
          }
          
        </Select>
      </FormControl> 
    </>
  )
}

export default SectionsSelect