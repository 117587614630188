import React, { useState } from 'react';
import Header from '../Header'
import AppCodeLogin from '../forms/AppCodeLogin'
import EmailLogin from '../forms/EmailLogin'
import { Button, Box, Paper, makeStyles } from '@material-ui/core';
import Stack from '../Stack';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#efefef',
    height: '100vh'
  }
}))

export default function Login() {
  const classes = useStyles()
  const [mode, setMode] = useState('appCode')
  

  return (
    <Stack gutter={3} className={classes.root}> 
      <Header title="Login" />

      <Paper elevation={1}>
        {mode === 'appCode' ? 
          <AppCodeLogin /> : 
          <EmailLogin />
        }
      </Paper>
      
      <Box>
        <Button fullWidth onClick={ () => { setMode(mode === 'appCode' ? 'email' : 'appCode')} }>
          Or, login with {mode === 'appCode' ? 'Email' : 'Auth Code'}
        </Button>
      </Box>

    </Stack>
  );
}
