import { useEffect, useState, useContext } from "react"
import { Star } from '@material-ui/icons';
import Header from '../Header'
import List from '../List'
import { get } from '../../api'
import SettingsContext from '../../settings'

export default function Home() {
  const [shows, setShows] = useState([])
  const context = useContext(SettingsContext)

  useEffect(() => {
    if ( shows.length > 0) return;
    get('shows')
      .then(resp => resp.json() )
      .then(json => {
        if ( json.shows ) {
          setShows(json.shows)
        }
      })
      .catch(console.error)
  })

  function setShow(item) {
    context.setShow(item)
  }

  const displayShows = () => {
    return shows.map(s => (
      {...s, 
        secondary: s.current ? 'Current Show' : null, 
        secondaryAction: context.show.id === s.id ? <Star /> : null
      }
    ))    
  }

  return (
    <>
      <Header title="Home" menu={true} />

      <List listItems={displayShows()} onItemClick={setShow} />
    </>
  )
}