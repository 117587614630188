
import React from 'react';
import { useParams } from 'react-router-dom'
import { List, ListItem, Typography, ListItemText } from '@material-ui/core';
import Stack from '../Stack';
import CheckInForm from '../forms/CheckInForm'
import PhoneForm from '../forms/PhoneForm'
import Header from '../Header'
import { get } from '../../api'
import LoadingScreen from '../LoadingScreen';

export default function Booth() {
  const { id } = useParams()
  const [loading, setLoading] = React.useState(true)
  const [booth, setBooth] = React.useState(null)

  React.useEffect(() => {
    if ( id === undefined ) return;
    setLoading(true)
    get(`booths/${id}`)
    .then(resp => resp.json())
    .then(json => {
      setBooth(json)
      setLoading(false)
    })
    .catch(console.error)
  }, [id])

  return (
    <>
      <Header title={ booth ? booth.vendor.name : ""} />
      { loading 
        ? <LoadingScreen open={loading} />
        : <Stack gutter={2}>
            <CheckInForm booth={booth} />
            <PhoneForm vendorId={booth.vendor.id} phone={booth.vendor.phone} />

            <>
              <Typography variant="caption" style={{textTransform: 'uppercase'}}>
                Booth Info
              </Typography>
              <List dense={true}>
                {booth.add_ons.map(add_on => (
                  <ListItem key={add_on.to_s}>
                    <ListItemText primary={add_on.to_s} />
                  </ListItem>
                ))}    
              </List>
            </>
            <>
              <Typography variant="caption" style={{textTransform: 'uppercase'}}>
                Load-in Door
              </Typography>
              <Typography>
                {booth.door}
              </Typography>
            </>
          </Stack>
      }
    </>
  )
}
