import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';

import { post } from '../../api'
import Stack from '../Stack';
import { Alert } from '@material-ui/lab';
import PasswordField from '../PasswordField';
import Button from '../Button'

export default function EmailLoginForm() {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)

  function handleLogin(e) {
    if ( e ) e.preventDefault()
    setSaving(true)
    if ( email === null || password === null ) {
      setError('Email and Password are required')
      return false
    }

    post('login', {
      session: {email: email, password: password}
    })
      .then(resp => {
        if (resp.ok) return {}
        else return resp.json()
      })
      .then(json => {
        if ( json.error ) setError(json.error)
        else history.push('/')
      })
      .catch(console.error)
      .finally(() => { setSaving(false)})

  }

  function handleChange(event) {
    event.target.name === 'email' ?
      setEmail(event.target.value) : 
      setPassword(event.target.value)
  }

  function clearError() {
    setError(null)
  }

  return (
    <form onSubmit={handleLogin}>
      <Stack gutter={3}>
        <Stack>
          <TextField
            autoComplete="email"
            autoFocus={true}
            fullWidth={true}
            label="Email"
            name="email"
            value={email}
            error={error === null ? false : true}
            onChange={handleChange}
            onFocus={ () => {setError(null)} }          
            />
          <PasswordField
            autoComplete="new-password"
            fullWidth={true}
            label="Password"
            name="password"
            value={password}
            error={error}
            onChange={handleChange}
            onFocus={clearError}
          />
        </Stack>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            endIcon={<ArrowForwardIcon />}
            onClick={handleLogin}
            loading={saving}
          >
            Login
          </Button>
        </Box>
      </Stack>
    </form>
  )
}