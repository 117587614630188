import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ExitToApp } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { Delete } from '../api'
import routes from '../routes'

export default function SideNav(props) {
  const history = useHistory()

  function handleLogout() {
    Delete('login')
      .then(resp => {
        history.push('/login')
      })
  }

  function handleClick(path) {
    props.toggleNav()
    history.push(path)
  }

  const items = routes.filter(r => r.nav !== undefined)

  return (
    <React.Fragment>
      <Drawer anchor={'left'} open={props.open} onClose={props.toggleNav}>
        <>
          <List>
            {items.map((item) => (
              <li key={item.text}>
                <ListItem button onClick={() => {handleClick(item.nav.to)}}>
                  <ListItemIcon>
                    <item.nav.icon />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
                {item.nav.divider && <Divider />}
              </li>
            ))}   
          </List>     
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Logout" onClick={handleLogout} />
          </ListItem>
        </List>      
        </>
      </Drawer>
    </React.Fragment>
  );
}