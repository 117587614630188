import { FormControl, IconButton, Input, InputAdornment, InputLabel } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react"
export default function PasswordField(props) {
  const [type, setType] = useState('password')

  const handleClickShowPassword = () => {
    setType(type === 'text' ? 'password' : 'text')
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <FormControl fullWidth={props.fullWidth || false}>
        <InputLabel>{props.label || "Password"}</InputLabel>
        <Input
          autoComplete="new-password"
          autoFocus={props.autoFocus || false}
          fullWidth={props.fullWidth || false}
          type={type}
          name="password"
          value={props.password}
          error={props.error === null ? false : true}
          onChange={props.onChange}
          onFocus={props.onFocus}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {type === 'text' ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }        
        />
      </FormControl>
    </>
  )
}