function host() {
  let host = 'https://admin.weddingexpo.co';
  let local = window.location.host.indexOf('localhost') > -1;
  let ngrok = window.location.host.indexOf('ngrok') > -1;
  if (local || ngrok) {
    host = 'http://localhost:3000'
  } else {
    if (window.location.protocol !== 'https:') {
      window.location.replace(`https://${window.location.host}`)
    }
  }
  return host
}

const Config = {
  host: host()
}

export default Config 