import { Typography } from "@material-ui/core"
import Header from '../Header'

export default function Cakepull() {
  return (
    <>
      <Header title="Cakepull"  />
      <Typography>Cakepull</Typography>
    </>
  )
}