import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    backdrop: {
      zIndex: theme.zIndex.appBar - 1,
      color: '#333',
    },
  }
});

export default function LoadingScreen(props) {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} invisible={true} open={props.open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
