import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textDecoration: 'none',
  },
  text: {
    maxWidth: '80%'
  }
}));

export default function ListItemLink(props) {
  const classes = useStyles()
  return (
    <Link to={props.to} className={classes.link}>
      <Typography component="div" className={classes.text}>
        {props.children[0]}
      </Typography>
      {props.children.slice(1)}
    </Link>
  )
}


<style>

</style>