import { Typography } from "@material-ui/core"
import Header from '../Header'

export default function Highlighted() {
  return (
    <>
      <Header title="Highlighted" />
      <Typography>Highlighted</Typography>
    </>
  )
}