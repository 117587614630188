import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List as MaterialList, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import ListItemLink from './ListItemLink'

const useStyles = makeStyles((theme) => ({
  ul: {
    paddingBottom: theme.spacing(24)
  },
  li: {
    padding: 0
  },
  text: {
    padding: theme.spacing(2),
  }
}))

const List = (props) => {
  const classes = useStyles();

  function Link ({item}) {
    return (
      <ListItemLink to={item.url}  onClick={() => item.onClick(item)}>
        <ListItemText primary={item.text || 'Add item.text'} />
        {item.icon && item.icon}
      </ListItemLink>
    )
  }
  
  function Text ({item}) {
    return (
      <ListItemText 
        className={classes.text} 
        primary={item.text || 'Add item.text'} 
        secondary={item.secondary ? item.secondary : null}
        onClick={ () => { props.onItemClick(item) } } 
      />
    )
  }
  
  return (
    <>
      <MaterialList className={classes.ul}>
        {props.listItems.map((item) => (
          <ListItem className={classes.li} selected={item.selected} disableGutters divider key={item.id || item.key}>
            { item.url 
              ? <Link item={item} /> 
              : <Text item={item} />
            }
            {item.secondaryAction &&
              <ListItemSecondaryAction>
                {item.secondaryAction}
              </ListItemSecondaryAction>
            }
          </ListItem>
        ))}
      </MaterialList>
    </>
  )
}

export default List