import { Typography } from "@material-ui/core"
import Header from '../Header'

export default function Surveys() {
  return (
    <>
      <Header title="Surveys" />
      <Typography>Surveys</Typography>
    </>
  )
}