// import { useHistory } from "react-router"
import config from "./config"

function _handler(path, method = 'GET', data) {

  const options = {
    method: method,
    credentials: 'include',
    headers: { 
      "Content-Type": 'application/json'
    },
  }

  if ( data ) options.body = JSON.stringify(data)
  let format = path.match(/\?/) === null ? '?format=json' : '&format=json'

  return fetch(`${config.host}/${path}${format}`, options)
    .then(resp => {
      if ( resp.status === 401) {
        // TODO: need to use route history?
        window.location = '/login'
        return false;
      } else {
        return resp
      }
    })
    .catch((err) => {
      
    })
}

export function get(url) {
  return _handler(url)
}

export function post(url, data) {
  return _handler(url, 'POST', data)
}

export function patch(url, data) {
  return _handler(url, 'PATCH', data)
}

export function Delete(url, data) {
  return _handler(url, 'DELETE', data)
}