import { FormControl, Input } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useState } from "react"
import { useParams } from "react-router"
import { patch } from '../../api'
import Button from '../Button'

export default function CheckInForm(props) {
  const [checkedIn, setCheckedIn] = useState(props.booth.checked_in_at)
  const [saving, setSaving] = useState(false)
  const { id } = useParams()

  function handleSubmit() {
    setSaving(true)
    patch(`booths/${id}`, 
      {booth: {checked_in_at: new Date()}}
    ).then((resp) => {
      setCheckedIn(true)
    })
    .catch(console.error)
    .finally(() => { setSaving(false)})
  }

  return (
    <>
      {checkedIn ? (
        <Alert severity="info"> Checked In </Alert>
      ) : (
        <form 
          onSubmit={handleSubmit}>
          <FormControl>
            <Input
              inputProps={{
                type: 'hidden',
                name: 'checked_in_at',
                value: true
              }}
            />
          </FormControl>      
          <FormControl>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="default"
              disableElevation
              loading={saving}
            >
              Check In
            </Button>
          </FormControl>
        </form>    
      )}    
    </>
  )
}